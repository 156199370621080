export const RELOVE_GST_RATE = 0.18;
export const DELHIVERY_API_PROVIDER = 'Delhivery API';

export const PACKAGE_BOX = {
    length: 35, // cms
    breadth: 10, // cms
    height: 5, // cms
    weight: '0.35', // kgs
    hsn_code: '997331' //Software
};

export const PACKAGE_ENVELOPE = {
    length: 39, // cms
    breadth: 36, // cms
    height: 2, // cms
    weight: '0.5', // kgs
    hsn_code: '6102' //Random Apparel
};

export const RELOVE_WAREHOUSE = {
    email: 'contact@relove.in',
    company_name: 'Relove',
    name: 'Prateek Gupte',
    phone: '9820773017',
    address: 'Room no 4, 1st Floor, Santosh Building, LJ Rd',
    address_2: 'Dadar West',
    city: 'Mumbai',
    state: 'Maharashtra',
    country: 'India',
    pin_code: '400028',
    pickup_location: 'relove_dadar',
    gst_no: '27AAJCB8040P1Z5',
    delhivery_pickup_location: 'SF RELOVE 0114094'
};

export const BUYER_ALTERNATE_CONTACT_NUMBER = '7045494448'; //Ravi's contact number

export const SHIPROCKET_API_CHANNEL_ID = 2128696;

//TODO Replace the constants on static pages
export const EMAIL_SENDER = 'Relove <home@relove.in>';
export const EMAIL_CONTACT = 'contact@relove.in';
export const EMAIL_REPLY_TO = 'contact@relove.in';
export const EMAIL_SUPPORT = 'contact@relove.in'; //Change to support@relove.in eventually
export const EMAIL_NOTIFICATIONS = 'notifications@relove.in'; // To prevent inbox spam
export const EMAIL_FINANCE = 'finance@relove.in';
export const CHAT_NUMBER = "+919321050103";
// export const CHAT_NUMBER = '+918591017139';
export const CHAT_SUPPORT = `https://api.whatsapp.com/send?phone=${CHAT_NUMBER}&text=I%20need%20help%20with%20my%20relove%20order`;
export const CHAT_BULK_PICKUP = `https://api.whatsapp.com/send?phone=${CHAT_NUMBER}&text=I%20have%20more%20than%203%20items%20to%20sell%2C%20I%20wish%20to%20request%20a%20bulk%20pickup.`;
export const CHAT_TRACK_ORDER = `https://api.whatsapp.com/send?phone=${CHAT_NUMBER}&text=Hi%21%20Could%20you%20please%20let%20me%20know%20the%20status%20of%20my%20order%3F%20My%20order%20number%20is%20`;

export const WAREHOUSE_TO_SELLER_SHIPMENT = 0;
export const SELLER_TO_BUYER_SHIPMENT = 1;

export const PAYOUT_METHOD_STORE_CREDIT = 'store_credit';
export const PAYOUT_METHOD_CASHOUT = 'payout';
export const PAYOUT_METHOD_NONE = 'none';

export const SUBMISSION_TYPE_VERIFIED = 'verified';
export const SUBMISSION_TYPE_MANUAL = 'manual';
export const SUBMISSION_TYPE_HUB = 'hub';
export const SUBMISSION_TYPE_RESCUE = 'rescue';

export const RELOVE_STATUS_NEW = 'New';
export const RELOVE_STATUS_PACKING = 'Packing';
export const RELOVE_STATUS_WAREHOUSE_TRANSIT = 'Transit from Warehouse';
export const RELOVE_STATUS_WAITING_FOR_VIDEO = 'Awaiting Seller Video';
export const RELOVE_STATUS_SELLER_TRANSIT = 'Transit from Seller';
export const RELOVE_STATUS_DELIVERED_TO_BUYER = 'Delivered to Buyer';
export const RELOVE_STATUS_PAID = 'Paid';
export const RELOVE_STATUS_CANCELLED = 'Cancelled';
export const RELOVE_STATUS_COMPLETE = 'Complete'; //TODO Integrate Payment Status
export const RELOVE_STATUS_ON_HOLD = 'On Hold';

export const TRANSACTION_STATUS_ON_HOLD = 'On Hold';
export const TRANSACTION_STATUS_COMPLETE = 'Complete';
export const TRANSACTION_STATUS_CANCELLED = 'Cancelled';

export const TRANSACTION_TYPE_RAZORPAY_DEPOSIT = 'razorpay deposit';
export const TRANSACTION_TYPE_SELLER_PAYOUT = 'seller payout';
export const TRANSACTION_TYPE_RELOVE_COMMISSION = 'relove commission';
export const TRANSACTION_TYPE_BRAND_COMMISSION = 'brand commission';
export const TRANSACTION_TYPE_COD_DEPOSIT = 'cod deposit';
export const TRANSACTION_TYPE_COUPON = 'coupon';

export const RECHARGE_TYPE_PREPAID = 'prepaid';
export const RECHARGE_TYPE_POSTPAID = 'postpaid';

export const SUBSCRIPTION_STATUS_ACTIVE = 'ACTIVE';
export const SUBSCRIPTION_STATUS_PENDING = 'PENDING';
export const SUBSCRIPTION_STATUS_VISITED = 'VISITED';
export const SUBSCRIPTION_STATUS_EXPIRED = 'EXPIRED';
export const SUBSCRIPTION_STATUS_CANCELLED = 'CANCELLED';
export const SUBSCRIPTION_STATUS_DECLINED = 'DECLINED';

export const SHIPPING_SELECTION_TYPE_MANUAL_PICKUP = "manual_pickup";

export const SUBSCRIPTION_BASIC = {
    amount: '60',
    name: 'Managed Resale',
    relove_comission: 0.667, //Comission of Total Take Rate
    relove_take_rate: 0.3,
    rescue_comission: 0.025,
    annual_discount: 15,
    //TODO add marketplace commission
    marketplace_relove_comission: 0.667,
    marketplace_rescue_comission: 0.025,
    //TODO add takeback commission
};

export const SUBSCRIPTION_STARTER = {
    amount: '25',
    name: 'Starter Resale',
    relove_comission: 0.834, //Comission of Total Take Rate
    relove_take_rate: 0.3,
    rescue_comission: 0.05,
    annual_discount: 15,
    //TODO add marketplace commission
    marketplace_relove_comission: 0.834,
    marketplace_rescue_comission: 0.05,
    //TODO add takeback commission
};

export const UNREQUIRED_VARIANT_OPTIONS_DURING_SUBMISSION = ['relove', 'mlveda_country', 'title'];

export const UNICOMMERCE_ITEM_NOT_FOUND = 'unicommerce_item_not_found';

export const REJECTION_REASONS = [
    'missing_image',
    'damaged_item',
    'damaged_item_fixable',
    'incorrect_product',
    'seller_withdrawn',
    'other'
];

//merchant_cancelled being used directly as a constant in the relove_app
//Check the dashboardstats function before modifying this
export const CANCELLATION_REASONS = [
    'buyer_cancelled',
    'buyer_cancelled_after_shipping',
    'seller_cancelled',
    'seller_cancelled_after_shipping',
    'damaged_item_no_return',
    'damaged_item_return',
    'merchant_cancelled',
    'logistics_failure',
    'other'
];

export const CURRENCY_DEFAULT = "₹";
export const CURRENCY_CODE_DEFAULT = "INR";


export const SUPPORTED_COUNTRIES = [
    {
        name: "India",
        iddPrefix: "+91"    //International direct calling prefix
    },
    {
        name: "USA",
        iddPrefix: "+1"
    }
];

export const SES_RELOVE_CONTACT_LIST_NAME = "ReloveContactList";
export const SES_RELOVE_CONTACT_LIST_TOPICS = ["size-notification", "post-order-marketing"];

//task names
//the following task names are used for calling appropriate fuctions
//for example, for the 'create_order' task, we might call duplicate_and_update() function or create_order() function depending on shop and platform
//the function which gets called for a task is defined in the `integration_mapping` table
export const TASK_CREATE_PRODUCT = 'create_product';
export const TASK_GET_PRODUCT = 'get_product';
export const TASK_SEARCH_PRODUCTS = 'search_products';

export const TASK_CREATE_VARIANT = 'create_variant';

export const TASK_GET_CUSTOMER = 'get_customer';

export const TASK_CREATE_ORDER = 'create_order';
export const TASK_REFUND_ORDER = 'refund_order';
export const TASK_CANCEL_ORDER = 'cancel_order';
export const TASK_FUlFIll_ORDER = 'fulfill_order';

export const SHOP_ANY = 'any';

//platforms
export const PLATFORM_SHOPIFY = 'shopify';

export const DEV_STORES = [
    'pranit-dev-store.myshopify.com',
    'relove-development.myshopify.com',
    'relove-app-rajat.myshopify.com',
];

// category tags
export const RESCUE_ITEM_PRE_DEFINED_TAGS = [
    'Kurtas',
    'Suits',
    'Dresses',
    'Tops',
    'T-shirts',
    'Shirts',
    'Skirts',
    'Shorts',
    'Jackets',
    'Kurtis',
    'Pants',
    'Trousers',
    'Jeans',
    'Kidswear',
    'Carriers',
    'Men',
    'Women',
    'Girls',
    'No Defect',
    'Minor Defect',
    'With Tags'
];

// for bulk uplaod worker
export const INITIATOR_HUB = 'hub';
export const INITIATOR_APP = 'app';

export const MARKETPLACES = ['getcircle.myshopify.com'];