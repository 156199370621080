import { Accordion } from 'react-bootstrap';
import styles from './Faq.module.css';

const FaqSeller = ({ page }) => {
    return (
        <Accordion className="mb-5">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        Do I have to wash the product?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        Yes! You are required to wash or dry clean the item before shipping it. Pass
                        on the love., just as you would expect it to come to you.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        What is your return policy?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        Relove transactions are not reversible. Relove does not offer returns to the
                        buyer or seller for reloved products, unless the product does not match the
                        condition listed.
                        <br />
                        Read our return policy{' '}
                        <a href="/return-policy" target="_blank">
                            here
                        </a>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        How will I get paid?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        48hrs after the item has been delivered you will receive a payment link on
                        your provided email id and phone number. (Unless an issue has been raised by
                        the buyer). You can choose the payment method of your choice - NEFT, RTGS,
                        UPI, IMPS and more.
                        <br />
                        You can also choose to get paid in store credit. Store credit is a discount
                        code that can be redeemed through the brand you have purchased. Discount
                        codes have no expiry date but they can be used only once.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        Are there any penalites or hidden charges?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        Relove retains the right to <b>charge a penalty</b> in the following
                        scenarios -
                        <ul>
                            <li>
                                The item is not received in the condition specified during listing.
                            </li>
                            <li>The item has not been cleaned or washed.</li>
                        </ul>
                        Relove will choose to charge a cleaning fee, or an adjustment to the price
                        of the item at it's discretion.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        What about the shipping label and the box?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        Relove will provide the packaging required to ship your item right from your
                        doorstep! You will receive a box or envelope with the shipping label already
                        attached. Just drop your cleaned/washed item in and we will arrange for the
                        pickup! The item must be sent within 3 business days.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        What if I change my mind?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        If you decide to change your mind and keep the item before it is sold, you
                        can let us know at contact@relove.in . We will remove the product from the
                        website. We recommend that you avoid using the item after it has been listed
                        for sale.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        What if the item is damaged in transit?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        When you ship the item, you will provide photographic evidence of it's
                        condition during packaging. If the item is damaged/lost during shipping,
                        Relove will take responsibility for the item and investigate the matter with
                        our courier partners.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        How did you decide the price?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        We set the price based on a unique algorithm that considers several factors
                        such as age, condition, style and many other factors. We aim to price the
                        item at a point that will most increase the likelihood of it's sale.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        Why are some items marked as 'This item cannot be resold' in my wardrobe?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        These items may be delisted from the store or may be custom items that are
                        no longer listed. Contact us over email at contact@relove.in if you believe
                        this is an error.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        I am unable to login or find my account?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        If you have purchased the item as a guest, you will need to first create an
                        account. Click on the create account on the wardrdobe page, use the same
                        email address you provided during your guest checkout.
                        <br />
                        Once your account is created, you can login and view your orders to resell.
                        <br />
                        Still not able to find your orders? Drop us a note at contact@relove.in
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
                <Accordion.Header>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusHeading : styles.heading
                        }
                    >
                        I have a lot of items to resell, can you pick them up?
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div
                        className={
                            page === 'orderStatus' ? styles.orderStatusContent : styles.content
                        }
                    >
                        Yes! If you have more than 3 items to resell, drop us a note at
                        contact@relove.in and we will pickup and photograph the items for you.
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default FaqSeller;
